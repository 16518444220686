import { FC, ReactNode, memo, useCallback, useMemo, useState } from "react"

import { useTranslation } from "next-i18next"

import {
  AppMenu,
  DropdownLangTime,
  DropdownUserV2,
  UtilCookies,
  useAssets,
  useReferralCode
} from "@fxce/fe-core"
import { LocaleType } from "@fxce/fe-core/types/GlobalTypes"
import { get, isEmpty } from "lodash"
import Link from "next/link"
import { useRouter } from "next/router"
import { useDispatch, useSelector } from "react-redux"
import Image from "@components/core/ImagePriority"
import ModalResult from "@components/pages/power-block-game/ModalResult"
import ModalRule from "@components/pages/power-block-game/ModalRule"
import { AUTH_UNAUTHENTICATED } from "@config/constants"
import env from "@config/env"
import { mapSSOUrl } from "@helpers/clientUtils"
import { logoutAct } from "@redux/actions/userActions"
import { putLang } from "@api/authAPI"

type WrapperLinkType = {
  children: ReactNode
  to: string
}

export const WrapperLink: FC<WrapperLinkType> = memo(({ children, to }) => {
  return <Link href={to}>{children}</Link>
})

const Header = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const router = useRouter()
  const user = useSelector((state: any) => state.rootUser.user)
  const assets = useSelector((state: any) => {
    return state.rootUser.assets
  })

  const convertAssets: any = useAssets(assets, null)
  const authCode = useSelector((state: any) => {
    return state.rootUser.authCode
  })

  const ibCodeFromCore = useReferralCode({
    env: env.APP_ENV
  })

  const isAuthticated = useMemo(() => !isEmpty(user) && !!user.id, [user])

  const isVI = useMemo(() => router?.locale === "vi", [router.locale])

  // const [href, setHref] = useState("")
  const [isOpenModalResult, setIsOpenModalResult] = useState(false)

  const dialedGames = useSelector((state) => {
    return get(state, "rootConfig.dialedGames", [])
  })

  const game = useSelector((state) => {
    return get(state, "rootConfig.game", {})
  })

  const [isOpenModalRule, setIsOpenModalRule] = useState(false)

  const onToggleRule = useCallback(() => {
    setIsOpenModalRule(!isOpenModalRule)
  }, [isOpenModalRule])

  const onToggleResult = () => {
    setIsOpenModalResult(!isOpenModalResult)
  }

  // useEffect(() => {
  //   setHref(window.location.href)
  // }, [dispatch])

  const onLogout = useCallback(() => {
    dispatch(logoutAct())
  }, [dispatch])

  const onChangeLanguage = useCallback(
    (value: string) => {
      router.locale = value
      router.push("#", "#", { locale: value })
      UtilCookies.setCookie("LANGUAGE", value, {})
      if (isAuthticated) {
        putLang(value)
      }
    },
    [isAuthticated, router]
  )

  return (
    <div className="layout-header">
      {isOpenModalResult && (
        <ModalResult
          isOpen={isOpenModalResult}
          game={game}
          dialedGames={dialedGames}
          toggle={onToggleResult}
          centered
        />
      )}

      {isOpenModalRule && (
        <ModalRule isOpen={isOpenModalRule} toggle={onToggleRule} />
      )}
      <div className="main-header">
        <div className="section-page">
          <div className="header">
            <div className="header-left">
              <Link passHref href="/" legacyBehavior>
                <div className="logo cursor">
                  <Image src="/logo.svg" alt="logo" width={107} height={32} />
                </div>
              </Link>
              <div className="header-page-menu light-theme">
                <AppMenu
                  env={env.APP_ENV}
                  type="full-width"
                  className="fxce__header-left__appmenu"
                  locale={router.locale as LocaleType}
                  isAuthenticated={isAuthticated}
                  target="_self"
                  Wrapper={WrapperLink}
                  ibCode={ibCodeFromCore}
                />
              </div>

              <div className="menu">
                <ul>
                  <li>
                    <div className="title-header" onClick={onToggleRule}>
                      {t("Game rules")}
                    </div>
                  </li>
                </ul>
                <ul>
                  <li>
                    <div className="title-header" onClick={onToggleResult}>
                      {t("Results")}
                    </div>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link
                      href={
                        isVI
                          ? "https://faq.fxce.com/search/category/power-block_634d29d8823cfc0001b1944e"
                          : "https://faq.fxce.com/en/search/category/power-block_634d29d8823cfc0001b1944e"
                      }
                      target="_blank"
                    >
                      FAQs
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="ticket cursor">
                <Link
                  passHref
                  href="/power-block-game#game-board-balls"
                  legacyBehavior
                >
                  <div className="ticket-buy">
                    <i className="font-icon font-icon-buy-ticket"></i>
                    {t("Buy ticket")}
                  </div>
                </Link>
              </div>
            </div>
            <div className="header-right">
              {!isAuthticated && authCode === AUTH_UNAUTHENTICATED && (
                <>
                  <Link href={mapSSOUrl(`/login`)} passHref legacyBehavior>
                    <div className="btn-login">{t("Login")}</div>
                  </Link>
                  <Link href={mapSSOUrl(`/register`)} passHref legacyBehavior>
                    <div className="btn-register">
                      <span>{t("Signup")}</span>
                    </div>
                  </Link>
                </>
              )}
              {isAuthticated && (
                <div className="light-theme">
                  <DropdownUserV2
                    Wrapper={WrapperLink}
                    env={env.APP_ENV}
                    user={user}
                    asset={convertAssets || assets}
                    onLogout={onLogout}
                    locale={router.locale as LocaleType}
                    ibCode={ibCodeFromCore}
                  />
                </div>
              )}
              <div className="light-theme">
                <DropdownLangTime
                  language={router.locale as LocaleType}
                  onChangeLang={onChangeLanguage}
                  haveTimeZone={false}
                  onChangeTimeZone={undefined}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(Header)
